import { Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import trapPlotApi from '../../../apis/TrapPlotApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import InsectsVariationStateTagComponent from '../../../components/InsectsVariationStateTagComponent/InsectsVariationStateTagComponent';
import CustomContext from '../../../contexts/CustomContext';
import { Page } from '../../../models/Elements';
import { CustomAuth, TrapPlot } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import paginationService from '../../../services/TableService';
import styles from './TrapPlotsComponent.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

/**
 * Returns the trap plots component.
 * @returns the traps plots component.
 */
const TrapPlotsComponent: React.FC<Props> = (props) => {
    /*** HOOKS ***/

    const { plotId } = props;
    const intl = useIntl();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [trapPlotsPage, setTrapPlotsPage] = useState<Page<TrapPlot>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('trapUuid');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                if (plotId) {
                    const trapsPlotsPage = await trapPlotApi.list(page, 20, sortField, sortOrder, +plotId, true);
                    setTrapPlotsPage(trapsPlotsPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth.companyId, plotId, intl, page, sortField, sortOrder]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    /*** VISUAL ***/

    const items = trapPlotsPage ? trapPlotsPage.content : [];
    const columns: ColumnsType<TrapPlot> = [
        {
            title: <FormattedMessage id="trap.shortUuid" />,
            dataIndex: ['trap', 'shortUuid'],
            key: 'shortUuid',
            width: 80,
            align: 'center',
            render: (value: string | undefined, trapPlot: TrapPlot) => <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>{trapPlot.trap?.shortUuid}</Link>
        },
        {
            title: <FormattedMessage id="trapImage.plague" />,
            dataIndex: ['trap', 'plague'],
            key: 'plague',
            width: 140,
            render: (value: string | undefined, trapPlot: TrapPlot) => (
                <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                    <ConstantLabel value={value} prefix="plague.type." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.date" />,
            dataIndex: ['latestTrapImage', 'date'],
            key: 'date',
            width: 180,
            align: 'center',
            render: (value: Dayjs, trapPlot: TrapPlot) =>
                value && (
                    <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                        <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" hour="2-digit" minute="2-digit" />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="trapImage.insectsCount" />,
            dataIndex: ['latestTrapImage', 'insectsCount'],
            key: 'insectsCount',
            width: 80,
            align: 'right',
            render: (value: number | undefined, trapPlot: TrapPlot) => (
                <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                    <strong>{value}</strong>
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.temperature" />,
            dataIndex: ['latestTrapImage', 'temperature'],
            key: 'temperature',
            width: 80,
            align: 'right',
            render: (value: number | undefined, trapPlot: TrapPlot) =>
                value && (
                    <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                        <FormattedNumber
                            value={value}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="celsius"
                            unitDisplay="short"
                            style={'unit' as any}
                        />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="trapImage.humidity" />,
            dataIndex: ['latestTrapImage', 'humidity'],
            key: 'humidity',
            width: 80,
            align: 'right',
            render: (value: number | undefined, trapPlot: TrapPlot) =>
                value && (
                    <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                        <FormattedNumber
                            value={value / 100}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="percent"
                            unitDisplay="short"
                            style={'percent' as any}
                        />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="trapImage.crop" />,
            dataIndex: 'crop',
            key: 'crop',
            width: 120,
            render: (value: string | undefined, trapPlot: TrapPlot) => (
                <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                    <ConstantLabel value={value} prefix="crop.type." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.farming" />,
            dataIndex: 'farming',
            key: 'farming',
            width: 120,
            render: (value: string | undefined, trapPlot: TrapPlot) => (
                <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                    <ConstantLabel value={value} prefix="farming.type." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.pheromoneRenewed" />,
            dataIndex: 'pheromoneRenewed',
            key: 'pheromoneRenewed',
            width: 100,
            align: 'center',
            render: (value: string | undefined, trapPlot: TrapPlot) => (
                <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                    {trapPlot.pheromoneChangeRequired ? (
                        <Tooltip title={<FormattedMessage id="trapPlot.pheromoneChangeRequired.tooltip" />}>
                            <span className={styles.pheromone}>
                                <FormattedDate value={trapPlot.pheromoneRenewed as any} year="numeric" month="2-digit" day="2-digit" />
                            </span>
                        </Tooltip>
                    ) : (
                        <span>
                            <FormattedDate value={trapPlot.pheromoneRenewed as any} year="numeric" month="2-digit" day="2-digit" />
                        </span>
                    )}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.gluePaperChangeRequired" />,
            dataIndex: ['latestTrapImage', 'gluePaperChangeRequired'],
            key: 'gluePaperChangeRequired',
            width: 60,
            align: 'center',
            render: (value: boolean, trapPlot: TrapPlot) => (
                <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                    {value && (
                        <Tooltip title={<FormattedMessage id="trapImage.gluePaperChangeRequired.tooltip" />}>
                            <InfoCircleOutlined className={styles.paper} />
                        </Tooltip>
                    )}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.insectsVariationState" />,
            dataIndex: ['latestTrapImage', 'insectsVariationState'],
            key: 'insectsVariationState',
            width: 90,
            align: 'center',
            render: (value: number | undefined, trapPlot: TrapPlot) => (
                <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                    <InsectsVariationStateTagComponent insectsVariationState={trapPlot.latestTrapImage?.insectsVariationState} />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trap.status" />,
            dataIndex: 'status',
            key: 'status',
            width: 90,
            align: 'center',
            render: (value: string | undefined, trapPlot: TrapPlot) => (
                <Link to={`/plots/${plotId}/traps/${trapPlot.id}`}>
                    <Tag color={value === 'ENABLED' ? 'blue' : 'default'}>
                        <ConstantLabel value={value} prefix="trap.status." />
                    </Tag>
                </Link>
            )
        }
    ];

    return (
        <Table
            dataSource={items}
            columns={columns}
            pagination={paginationService.createPagination(trapPlotsPage, { hideOnSinglePage: true })}
            rowKey="id"
            onChange={handleTableChange}
            sortDirections={['ascend', 'descend']}
            showSorterTooltip={false}
            loading={loading === 'loading'}
            onRow={(record, index) => ({
                className: record.status === 'DISABLED' ? styles.disabled : undefined
            })}
        />
    );
};
export default TrapPlotsComponent;

interface Props {
    plotId: number | undefined;
}
