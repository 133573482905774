import { ThemeConfig } from 'antd';

class AppThemeComponent {
    getAppTheme = () => {
        const appTheme: ThemeConfig = {
            token: {
                colorPrimary: '#397350',
                colorLink: '#000',
                colorLinkActive: '#000',
                colorLinkHover: '#000',
                fontFamily: 'Inter-Regular'
            },
            components: {
                Button: {
                    colorPrimary: '#397350',
                    primaryShadow: 'transparent'
                },
                Modal: {
                    colorPrimary: '#397350'
                },
                Layout: {
                    bodyBg: '#f5f5f5'
                },
                Card: {
                    colorBgContainer: '#fff',
                    borderRadiusLG: 12,
                    borderRadiusSM: 12,
                    borderRadiusXS: 12
                },
                Menu: {
                    colorItemBgSelected: '#397350',
                    colorItemTextSelected: '#fff',
                    radiusItem: 12
                },
                Input: {
                    colorBgContainer: '#E3EBE6',
                    colorBorder: '#E3EBE6',
                    colorTextDisabled: 'rgba(0, 0, 0, 0.88)'
                },
                InputNumber: {
                    colorBgContainer: '#E3EBE6',
                    colorBorder: 'transparent',
                    colorTextDisabled: 'rgba(0, 0, 0, 0.88)'
                },
                DatePicker: {
                    colorBgContainer: '#E3EBE6',
                    colorBorder: '#397350',
                    colorTextDisabled: 'rgba(0, 0, 0, 0.88)',
                    activeBorderColor: '#f3f6f4',
                    cellHoverWithRangeBg: '#f3f6f4',
                    cellHoverBg: '#f3f6f4',
                    activeShadow: '#f3f6f4',
                    cellRangeBorderColor: '#f3f6f4',
                    hoverBorderColor: '#f3f6f4'
                },
                Select: {
                    colorBgContainer: '#E3EBE6',
                    colorBorder: 'transparent',
                    colorTextDisabled: 'rgba(0, 0, 0, 0.88)'
                },
                Tabs: {
                    colorPrimary: 'rgba(43, 47, 50, 1)',
                    margin: 30
                }
            }
        };

        return appTheme;
    };
}

const appThemeComponent: AppThemeComponent = new AppThemeComponent();
export default appThemeComponent;
