import { PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Tag, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import companyUserApi from '../../apis/CompanyUserApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../contexts/CustomContext';
import { Page } from '../../models/Elements';
import { CustomAuth, User } from '../../models/Entities';
import { UserType } from '../../models/Types';
import alertService from '../../services/AlertService';
import paginationService from '../../services/TableService';

/**
 * Returns the users page.
 * @returns the users page.
 */

const UsersPage: React.FC = () => {
    /***HOOKS */
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const companyId = auth.companyId as number;
    const [filter, setFilter] = useState<Filter>({});
    const [usersPage, setUsersPage] = useState<Page<User>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('lastName');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    //list users
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const userPage = await companyUserApi.list(page, paginationService.pageSize, sortField, sortOrder, companyId, filter.searchText);
                setUsersPage(userPage);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [companyId, intl, page, sortField, sortOrder, filter]);

    /*** METHODS ***/
    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
        setPage(0);
    };

    /*** VISUAL ***/

    const items = usersPage ? usersPage.content : [];
    const columns: ColumnsType<User> = [
        {
            title: <FormattedMessage id="user.lastName" />,
            dataIndex: 'lastName',
            key: 'lastName',
            width: 250,
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, user: User) => <Link to={`/users/${user.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="user.firstName" />,
            dataIndex: 'firstName',
            key: 'firstName',
            width: 250,
            sorter: true,
            render: (value: string, user: User) => <Link to={`/users/${user.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="user.email" />,
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            render: (value: string, user: User) => <Link to={`/users/${user.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="user.notifications" />,
            dataIndex: 'notifications',
            key: 'notifications',
            width: 160,
            sorter: true,
            align: 'center',
            render: (value: boolean, user: User) => <Link to={`/users/${user.id}`}>{value && <CheckOutlined />}</Link>
        },
        {
            title: <FormattedMessage id="user.type" />,
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            sorter: false,
            render: (value: UserType, user: User) => (
                <Link to={`/users/${user.id}`}>
                    {value === 'COMPANY_ADMIN' && (
                        <Tag color="green">
                            <FormattedMessage id={`user.type.${value}`} />
                        </Tag>
                    )}
                    {value === 'COMPANY_USER' && (
                        <Tag color="blue">
                            <FormattedMessage id={`user.type.${value}`} />
                        </Tag>
                    )}
                </Link>
            )
        }
    ];

    return (
        <LayoutComponent title={<FormattedMessage id="users.title" />} menu="users" path={[{ path: '/users', name: <FormattedMessage id="users.title" /> }]}>
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row>
                    <Col span={20} lg={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'users.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} lg={6} className="table-buttons">
                        <Link to="/users/new">
                            <Tooltip title={<FormattedMessage id="users.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Link>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={paginationService.createPagination(usersPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default UsersPage;

interface Filter {
    searchText?: string;
}
