import { Button, Checkbox, Col, Form, Input, Row, Select, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { User } from '../../../models/Entities';
import userApi from '../../../apis/UserApi';
import alertService from '../../../services/AlertService';
import { SaveOutlined } from '@ant-design/icons';
import { userTypes } from '../../../models/Types';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

/***Returns the account user component.
 * @returns the account user component.
 */

const AccountUserComponent: React.FC = () => {
    /*** HOOKS ***/
    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'initializing' | 'loading'>();
    const [user, setUser] = useState<User>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');
                const user = await userApi.getCurrent();
                form.setFieldsValue(user);
                setUser(user);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');
            let updatedUser: User = Object.assign({}, user, values);

            if (user) {
                updatedUser = await userApi.updateCurrent(updatedUser);
                setUser(updatedUser);

                message.success(intl.formatMessage({ id: 'status.saved' }));
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL */

    const userTypeOptions = userTypes.map((t) => (
        <Select.Option key={t} value={t}>
            <FormattedMessage id={`user.type.${t}`} />
        </Select.Option>
    ));

    return (
        <Form form={form} onFinish={save} colon={false} layout="vertical">
            <Row gutter={[12, 12]}>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={<FormattedMessage id="user.email" />}
                        name="email"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />
                            }
                        ]}
                    >
                        <Input size="large" maxLength={200} disabled />
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={<FormattedMessage id="user.type" />}
                        name="type"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select size="large" disabled>
                            {userTypeOptions}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[12, 12]}>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={<FormattedMessage id="user.firstName" />}
                        name="firstName"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input size="large" maxLength={200} />
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={<FormattedMessage id="user.lastName" />}
                        name="lastName"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input size="large" maxLength={200} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[12, 12]}>
                <Col span={24} lg={24}>
                    <Form.Item name="notifications" valuePropName="checked" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                        <Checkbox>
                            <FormattedMessage id="user.notifications.desc" />
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[24, 24]} className="buttons">
                <Col span={24}>
                    <Space>
                        <Link to="/">
                            <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                        </Link>
                        <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                            <FormattedMessage id="button.save" tagName="span" />
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export default AccountUserComponent;
