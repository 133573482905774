import { Button, Col, Form, Image, Input, message, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import userApi from '../../../apis/UserApi';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import PublicLayoutComponent from '../../../components/PublicLayoutComponent/PublicLayoutComponent';
import CustomContext from '../../../contexts/CustomContext';
import { CompanyAdmin } from '../../../models/Entities';
import syncrologisIcon from '../../../resources/images/agrisens-icon.svg';
import authService from '../../../services/AuthService';
import styles from './InitPage.module.scss';

/**
 * Returns the init page. It allows the company admin initialization.
 * @returns the init page.
 */
const InitPage: React.FC = () => {
    const context = useContext(CustomContext);
    const { auth } = context;
    const [desktop] = useResponsiveLayout();

    if (auth && auth.initialized) {
        return <Navigate to="/" />;
    } else {
        return (
            <PublicLayoutComponent>
                <Col span={12}></Col>
                <Col md={24} lg={12} className={`${styles.form} ${desktop ? styles.desktop : styles.mobile}`}>
                    <CompanyAdminForm />
                </Col>
            </PublicLayoutComponent>
        );
    }
};
export default InitPage;

const CompanyAdminForm: React.FC = () => {
    /*** HOOKS ***/
    const context = useContext(CustomContext);
    const [form] = Form.useForm();
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<'loading'>();

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');
            const cognitoUser = await authService.getCognitoUser();
            const companyAdmin: CompanyAdmin = Object.assign({}, values, {
                email: cognitoUser.attributes.email,
                notifications: true
            });
            await userApi.createCompanyAdmin(companyAdmin);

            const auth = await authService.get();
            context.setAuth(auth);
            navigate('/');
        } catch (error: any) {
            if (error.message) {
                message.error(error.message, 6);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
            }
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <Form form={form} onFinish={save} colon={false} layout="vertical">
            <Row>
                <Col span={24} className={styles.panel}>
                    <Image src={syncrologisIcon} preview={false} />
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <h1>
                        <FormattedMessage id="init.title" />
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="companyName" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]} required={false}>
                        <Input maxLength={100} size="large" placeholder={intl.formatMessage({ id: 'init.companyName' })} />
                    </Form.Item>
                </Col>{' '}
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="firstName" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]} required={false}>
                        <Input maxLength={100} size="large" placeholder={intl.formatMessage({ id: 'init.firstName' })} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="lastName" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]} required={false}>
                        <Input maxLength={100} size="large" placeholder={intl.formatMessage({ id: 'init.lastName' })} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={8} className={styles.button}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} block className={styles.signupButton}>
                            <FormattedMessage id="init.submit" tagName="span" />
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
